module.exports = [{
      plugin: require('/opt/atlassian/pipelines/agent/build/node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".md",".mdx"],"remarkPlugins":[[null,{"type":"yaml","marker":"-"}],null],"rehypePlugins":[null,null],"gatsbyRemarkPlugins":[],"defaultLayouts":{"default":"/opt/atlassian/pipelines/agent/build/node_modules/gatsby-theme-docz/src/base/Layout.js"}},
    },{
      plugin: require('/opt/atlassian/pipelines/agent/build/node_modules/gatsby-theme-docz/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/atlassian/pipelines/agent/build/node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
