// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---readme-md": () => import("/opt/atlassian/pipelines/agent/build/README.md" /* webpackChunkName: "component---readme-md" */),
  "component---src-components-alert-mdx": () => import("/opt/atlassian/pipelines/agent/build/src/components/Alert.mdx" /* webpackChunkName: "component---src-components-alert-mdx" */),
  "component---src-components-button-mdx": () => import("/opt/atlassian/pipelines/agent/build/src/components/Button.mdx" /* webpackChunkName: "component---src-components-button-mdx" */),
  "component---src-pages-content-figures-mdx": () => import("/opt/atlassian/pipelines/agent/build/src/pages/content/figures.mdx" /* webpackChunkName: "component---src-pages-content-figures-mdx" */),
  "component---src-pages-content-images-mdx": () => import("/opt/atlassian/pipelines/agent/build/src/pages/content/images.mdx" /* webpackChunkName: "component---src-pages-content-images-mdx" */),
  "component---src-pages-content-logos-mdx": () => import("/opt/atlassian/pipelines/agent/build/src/pages/content/logos.mdx" /* webpackChunkName: "component---src-pages-content-logos-mdx" */),
  "component---src-pages-content-tables-mdx": () => import("/opt/atlassian/pipelines/agent/build/src/pages/content/tables.mdx" /* webpackChunkName: "component---src-pages-content-tables-mdx" */),
  "component---src-pages-extend-custom-components-mdx": () => import("/opt/atlassian/pipelines/agent/build/src/pages/extend/customComponents.mdx" /* webpackChunkName: "component---src-pages-extend-custom-components-mdx" */),
  "component---src-pages-content-typography-mdx": () => import("/opt/atlassian/pipelines/agent/build/src/pages/content/typography.mdx" /* webpackChunkName: "component---src-pages-content-typography-mdx" */),
  "component---src-pages-extend-iconography-mdx": () => import("/opt/atlassian/pipelines/agent/build/src/pages/extend/iconography.mdx" /* webpackChunkName: "component---src-pages-extend-iconography-mdx" */),
  "component---src-pages-getting-started-browsers-and-devices-mdx": () => import("/opt/atlassian/pipelines/agent/build/src/pages/getting-started/browsers-and-devices.mdx" /* webpackChunkName: "component---src-pages-getting-started-browsers-and-devices-mdx" */),
  "component---src-pages-getting-started-contents-mdx": () => import("/opt/atlassian/pipelines/agent/build/src/pages/getting-started/contents.mdx" /* webpackChunkName: "component---src-pages-getting-started-contents-mdx" */),
  "component---src-pages-utilities-sizing-mdx": () => import("/opt/atlassian/pipelines/agent/build/src/pages/utilities/sizing.mdx" /* webpackChunkName: "component---src-pages-utilities-sizing-mdx" */),
  "component---src-pages-utilities-spacing-mdx": () => import("/opt/atlassian/pipelines/agent/build/src/pages/utilities/spacing.mdx" /* webpackChunkName: "component---src-pages-utilities-spacing-mdx" */),
  "component---src-pages-utilities-vertical-align-mdx": () => import("/opt/atlassian/pipelines/agent/build/src/pages/utilities/vertical-align.mdx" /* webpackChunkName: "component---src-pages-utilities-vertical-align-mdx" */),
  "component---src-pages-utilities-visibility-mdx": () => import("/opt/atlassian/pipelines/agent/build/src/pages/utilities/visibility.mdx" /* webpackChunkName: "component---src-pages-utilities-visibility-mdx" */),
  "component---src-pages-getting-started-download-mdx": () => import("/opt/atlassian/pipelines/agent/build/src/pages/getting-started/download.mdx" /* webpackChunkName: "component---src-pages-getting-started-download-mdx" */),
  "component---src-pages-content-palette-mdx": () => import("/opt/atlassian/pipelines/agent/build/src/pages/content/palette.mdx" /* webpackChunkName: "component---src-pages-content-palette-mdx" */),
  "component---src-pages-404-js": () => import("/opt/atlassian/pipelines/agent/build/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-mdx": () => import("/opt/atlassian/pipelines/agent/build/src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */)
}

